import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import BlueButton from "../components/BlueButton";
import { saveVisitorProducts } from "../lib/api";

import { removeSelectedProduct } from "../lib/appSlice";

function Queue() {
    const selectedEvent = useSelector((state) => state.app.selectedEvent);
    const selectedProducts = useSelector((state) => state.app.selectedProducts);
    const visitor = useSelector((state) => state.app.visitor);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [data, setData] = useState('No result');
    // const [qrCodeScanner, setQrCodeScanner] = useState();

    const onRemoveClick = (product) => {
        dispatch(removeSelectedProduct(product));
    }
    const submitInterest = async () => {
        console.log('submit products', selectedProducts, visitor);
        await saveVisitorProducts(visitor, selectedProducts);
        navigate('/confirm');
    }
    
    useEffect(() => {
        if (selectedEvent === null) {
            navigate('/event')
        }
    }, []); // eslint-disable-line


    return (
        <div className="bg-white grow px-5">
            {selectedEvent &&
                <div className="max-w-sm mx-auto">
                    <span className="text-assa-blue">Event Name:</span> {selectedEvent.attributes.Name}
                    <br />
                    <div className="pt-5 text-center w-10 mx-auto">
                        <img src="./images/check_icon.png" alt="Checkmark Icon" />
                    </div>
                    <div className=" pt-5 text-center">
                        Added to your queue
                    </div>
                    <div className="">
                        {selectedProducts.map((p, i) => (
                            <div className="border-b border-black pt-2">
                                <div>
                                    Item {i + 1}
                                </div>
                                <div>
                                    {p.attributes.name}
                                </div>
                                <div className="text-assa-blue flex justify-end" onClick={()=>onRemoveClick(p,i)}>
                                    Remove&nbsp;
                                    <div className="pt-1">
                                        <img src="./images/trashcan.png" alt="delete icon" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-between pt-5">
                        <Link to="/scanner"><BackButton>Add More</BackButton></Link>
                        <BlueButton onClick={() => submitInterest()}>Submit</BlueButton>
                    </div>
                </div>
            }
        </div>
    )
}

export default Queue;