import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Event from "./pages/Event";
import IntakeForm from "./pages/IntakeForm";
import ScannerInfo from "./pages/ScannerInfo";
import Scanner from "./pages/Scanner";
import { useEffect } from "react";
import { loadAppData } from "./lib/api";
import { setEvents, setProducts, setSolutionsByChallenge, setSolutionsByMarket, setSolutionsByRole } from "./lib/appSlice";
import { useDispatch } from "react-redux";
import Queue from "./pages/Queue";
import Confirm from "./pages/Confirm";


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const results = await loadAppData();
      // console.log('results', results);
      dispatch(setEvents(results[0].data.data));
      dispatch(setSolutionsByRole(results[1].data.data.attributes.solutions));
      dispatch(setSolutionsByMarket(results[2].data.data.attributes.solutions));
      dispatch(setSolutionsByChallenge(results[3].data.data.attributes.solutions));
      dispatch(setProducts(results[4].data.data));
    })();
  }, []); // eslint-disable-line

  return (
    <div className="app flex flex-col inset-0 relative">
      <header className="py-10">
        <div className="max-w-sm mx-auto flex justify-center">
          <img src="/images/AssaLogo.png" alt="Assa Abloy Logo" />
        </div>
      </header>
      <Routes>
        <Route path="/intake" element={<IntakeForm />} />
        <Route path="/event" element={<Event />} />
        <Route path="/scannerInfo" element={<ScannerInfo />} />
        <Route path="/scanner" element={<Scanner />} />
        <Route path="/queue" element={<Queue />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
