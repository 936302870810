import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  events: null,
  selectedEvent: null,
  visitor: null,
  solutionsByRole: [],
  solutionsByMarket: [],
  solutionsByChallenge: [],
  products: [],
  selectedProducts: []
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setSolutionsByRole: (state, action) => {
      state.solutionsByRole = action.payload;
    },
    setSolutionsByMarket: (state, action) => {
      state.solutionsByMarket = action.payload;
    },
    setSolutionsByChallenge: (state, action) => {
      state.solutionsByChallenge = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setSelectedEventById: (state, action) => {
      const eventId = action.payload;
      const events = [...state.events];
      const event = events.find((e) => parseInt(eventId) === parseInt(e.id));
      state.selectedEvent = event;
    },
    addSelectedProduct: (state, action) => {
      const product = action.payload;
      const productInList = state.selectedProducts.some((p) => {
        return p.id === product.id
      });
      if (!productInList) {
        state.selectedProducts = [...state.selectedProducts, product];
      }
    },
    removeSelectedProduct: (state, action) => {
      const product = action.payload;
      const filtered = state.selectedProducts.filter((p) => p.id !== product.id)
      state.selectedProducts = [...filtered];
    },
    setVistor: (state, action) => {
      state.visitor = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setEvents,
  setSelectedEvent,
  setSelectedEventById,
  setVistor,
  setProducts,
  setSolutionsByChallenge,
  setSolutionsByMarket,
  setSolutionsByRole,
  addSelectedProduct,
  removeSelectedProduct
} = appSlice.actions

export default appSlice.reducer