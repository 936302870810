import { Html5Qrcode } from "html5-qrcode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/BlueButton";
import { beep } from "../lib/api";
import { addSelectedProduct } from "../lib/appSlice";
import { Toaster, toast } from "react-hot-toast";

function debounce(func, timeout = 1000) {
    let timer;
    console.log('build debounce', timeout);
    return (...args) => {
        console.log('ran')
        if ( !timer ) {
            console.log('call function', timer);
            func.apply(this, args);
        
        // clearTimeout(timer);
            timer = setTimeout(() => {
                console.log('reset timer');
                timer = undefined;
            }, timeout);
        }
    };
}

function Scanner() {
    const selectedEvent = useSelector((state) => state.app.selectedEvent);
    const products = useSelector((state) => state.app.products);
    const selectedProducts = useSelector((state) => state.app.selectedProducts);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // let debounceDispatch;
    // const [data, setData] = useState('No result');
    // const [qrCodeScanner, setQrCodeScanner] = useState();

    useEffect(() => {
        if (selectedEvent === null) {
            navigate('/event', { replace: true })
        }
    }, []); // eslint-disable-line

    // debounceDispatch = debounce(dispatch); // eslint-disable-line
    const onQRCodeScan = (e) => {
        const product = products.find((p) => {
            return p.attributes.qrcode_url === e;
        });
        if (product) {
            console.log('p scanned', e);
            beep();
            // setTimeout(() => {
                toast(`"${product.attributes.name}" added to your list`);
                dispatch(addSelectedProduct(product));

            // }, 1)

        } else {
            toast('QR Code Scanned, but Product not found');
        }
    };
    const debouncedQRCodeScan = debounce(onQRCodeScan, 3000);

    useEffect(() => {
        const html5QrCode = new Html5Qrcode("scanner");
        Html5Qrcode.getCameras().then(devices => {
            if (devices && devices.length) {
                // console.log('devices', devices);
                // var cameraId = devices[2].id;
                const config = { fps: 10, qrbox: { width: 250, height: 250 } };
                html5QrCode.start({ facingMode: "environment" }, config, debouncedQRCodeScan);
            }
        }).catch(err => {
            console.log('cant get cameras', err);
        });

        return async function cleanup() {
            // console.log('scanner cleanup');

            await html5QrCode.stop();
            html5QrCode.clear();
        };
    }, []); // eslint-disable-line
    return (
        <div className="bg-white grow px-5">
            <div><Toaster /></div>
            {selectedEvent &&
                <div className="max-w-sm mx-auto">
                    <div className="py-6 flex">
                        <span className="text-assa-blue font-bold">Event Name:</span>
                        &nbsp;{selectedEvent.attributes.Name}
                    </div>
                    <br />
                    <div className="border">
                        <div id="scanner" className="p-5"></div>
                    </div>
                    <div className="flex justify-center pt-10" onClick={() => { beep(); }}>
                        <span className="text-assa-blue">{selectedProducts.length}:</span>&nbsp;Items Added
                    </div>
                    <div className="flex justify-center pt-10">
                        <Link to="/queue" ><Button >{selectedProducts.length === 0 ? 'Quit' : 'Review & Finish'}</Button></Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default Scanner;