import { Link } from "react-router-dom";
import Button from "../components/Button";

function Home() {
    return (

        <div className="max-w-sm text-white mx-auto flex justify-center flex flex-col text-center px-5">
            <h1>WELCOME</h1>
            <p className="pb-10">
                We see you are trying to request information for this product.
                <br /><br />
                We are happy to get you that information, simply click the button below to get started.
            </p>
            <Link to="/event">
                <Button >
                    Get Started
                </Button>
            </Link>
        </div>

    );
}

export default Home;