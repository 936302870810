import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import EventSelect from "../components/EventSelect";
import { beep, loadNearestEvent } from "../lib/api";
import { setSelectedEvent, setSelectedEventById } from "../lib/appSlice";


function Event() {
    const [location, setLocation] = useState();
    const [loadingLocation, setLoadingLocation] = useState();
    const [error, setError] = useState(false);
    const events = useSelector((state) => state.app.events);
    const selectedEvent = useSelector((state) => state.app.selectedEvent);
    const dispatch = useDispatch();

    const selectEvent = (eventId) => {
        const event = events.find((e) => parseInt(eventId) === parseInt(e.id));
        dispatch(setSelectedEvent(event));
    }

    useEffect(() => {
        if (!location) {
            setLoadingLocation(true);
            navigator.geolocation.getCurrentPosition(async (position) => {
                console.log(position.coords);
                setLocation(position.coords);
                try {
                    const nearestData = await loadNearestEvent(position.coords);
                    const nearest = nearestData.data[0];
                    if ( nearest ) {
                        console.log('found nearest', nearest.id, events);
                        dispatch(setSelectedEventById(nearest.id));
                    }
                    setLoadingLocation(false);
                } catch (e) {
                    setLoadingLocation(false);
                }
            }, (error) => {
                console.log('geo errror', error);
                setError(true);
                setLoadingLocation(false);
            });
        }
    }, []); // eslint-disable-line

    return (
        <div className="max-w-sm mx-auto text-center px-5">
            {!error && !location &&
                <div className="pb-5 text-white">loading...</div>
            }
            {selectedEvent &&
                <>
                    {/* <div>Location {location.latitude} {location.longitude}</div> */}
                    <p className="pb-5">You have selected the following event. If this correct, please select “Get Started”.</p>
                    <div className="border border-white rounded text-white py-4">
                        <h1 className="font-black">{selectedEvent.attributes.Name}</h1>
                        <div>
                            {selectedEvent.attributes.Address}<br />
                            {selectedEvent.attributes.City}, {selectedEvent.attributes.State}  {selectedEvent.attributes.Zip}
                        </div>
                        <div className="py-10">
                            {new Date(selectedEvent.attributes.start_date).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}
                            &nbsp;|&nbsp;
                            {new Date(selectedEvent.attributes.start_date).toLocaleTimeString('en-us', { hour: "numeric", minute: "2-digit", hour12: true })}
                            &nbsp;-&nbsp;
                            {new Date(selectedEvent.attributes.end_date).toLocaleTimeString('en-us', { hour: "numeric", minute: "2-digit", hour12: true })}
                        </div>
                    </div>
                    <div className="-mt-5">
                        <Link to="/intake" onClick={() => beep()}><Button >Get Started</Button></Link>
                    </div>

                    <div className="mt-10">
                        If not, please select from below.
                        <EventSelect events={events} onEventChange={selectEvent} />

                    </div>
                </>
            }
            {(!selectedEvent) &&
                <>
                    <p className="mb-10">Welcome to the ASSA ABLOY Innovation Fleet. To ensure we have you at the correct event, please select your event below.</p>
                    <EventSelect events={events} onEventChange={selectEvent} />
                    {loadingLocation &&
                        <div className="pt-4 text-white">
                            Looking up Nearest Event...
                        </div>
                    }
                </>
            }
        </div>
    );

}

export default Event;