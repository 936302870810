
function BlueButton(props) {
    return (<button {...props} className="arrow blue mx-auto py-2 px-4 bg-assa-blue rounded text-white hover:border-assa-blue hover:bg-white hover:text-assa-blue border flex">
        <div className="pr-2">
            {props.children}
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.22998 20.23L7.99998 22L18 12L7.99998 2L6.22998 3.77L14.46 12L6.22998 20.23Z" fill="currentColor"/>
        </svg>
    </button>);
}

export default BlueButton;