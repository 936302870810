import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlueButton from "../components/BlueButton";
import { string, boolean, object } from 'yup';
import { createVisitor } from "../lib/api";
import { setVistor } from "../lib/appSlice";

function AssaInput(props) {
    return <Field {...props} className={`bg-gray-200 border-b-4 rounded m-2  w-full p-1 ${props.error ? 'drop-shadow-error': 'drop-shadow-input'}`} />
}
function AssaSelect(props) {
    return <Field as="select" {...props} className={`bg-gray-200 border-b-4 rounded m-2 w-full p-1 ${props.error ? 'drop-shadow-error': 'drop-shadow-input'}`}>{props.children}</Field>
}
function AssaRow(props) {
    return <div className="w-full flex items-center">{props.children}</div>
}

function IntakeForm() {
    const selectedEvent = useSelector((state) => state.app.selectedEvent);
    const solutionsByRole = useSelector((state) => state.app.solutionsByRole);
    const solutionsByMarket = useSelector((state) => state.app.solutionsByMarket);
    const solutionsByChallenge = useSelector((state) => state.app.solutionsByChallenge);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedEvent === null) {
            navigate('/event')
        }
    }, []); // eslint-disable-line

    return (
        <div className="bg-white grow px-5">
            {selectedEvent &&
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        zip: '',
                        country: 'U.S.',
                        company: '',
                        role: '',
                        market: '',
                        challenge: '',
                        receiveMedia: false
                    }}
                    validationSchema={object().shape({
                        firstName: string().required(),
                        lastName: string().required(),
                        email: string().email().required(),
                        phone: string().required(),
                        zip: string().required(),
                        country: string().required(),
                        company: string().required(),
                        role: string().required(),
                        market: string().required(),
                        challenge: string(),
                        receiveMedia: boolean().required()
                    })}
                    onSubmit={async (values) => {
                        console.log('sumbit,', values)
                        const visitor = await createVisitor(values, selectedEvent);
                        dispatch(setVistor(visitor))
                        // alert(JSON.stringify(visitor, null, 2));
                        navigate('/scannerInfo/')

                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            {/* {JSON.stringify(errors)} */}
                            <div className="max-w-sm mx-auto">
                                <div className="py-6 flex">
                                    <span className="text-assa-blue font-bold">Event Name:</span>
                                    &nbsp;{selectedEvent.attributes.Name}
                                </div>
                                <span className="text-assa-blue">*</span> Denotes Required Fields
                                <AssaRow>
                                    <AssaInput name="firstName" placeholder="First Name *" error={errors.firstName && touched.firstName} />
                                    <AssaInput name="lastName" placeholder="Last Name *" error={errors.lastName && touched.lastName} />
                                </AssaRow>
                                <AssaRow>
                                    <AssaInput name="email" type="email" placeholder="Email *" error={errors.email && touched.email} />
                                </AssaRow>
                                <AssaRow>
                                    <AssaInput name="phone" placeholder="Phone Number *" error={errors.phone && touched.phone}/>
                                </AssaRow>
                                <AssaRow>
                                    <AssaInput style={{ width: 'unset' }} name="zip" placeholder="Zip Code *" error={errors.zip && touched.zip}/>
                                    <Field type="radio" name="country" value="U.S." id="us_country" checked />&nbsp;<label htmlFor="us_country">U.S.</label>&nbsp;&nbsp;&nbsp;
                                    <Field type="radio" name="country" value="Canada" id="canada_country" />&nbsp;<label htmlFor="canada_country">Canada</label>
                                </AssaRow>
                                <AssaRow>
                                    <AssaInput name="company" placeholder="Company Name *" error={errors.company && touched.company}/>
                                </AssaRow>
                                <AssaRow>
                                    <AssaSelect name="role" error={errors.role && touched.role}>
                                        <option value="" disabled selected>Solutions by Job Role *</option>
                                        {solutionsByRole.map((s) => <option key={s.id}>{s.name}</option>)}
                                    </AssaSelect>
                                </AssaRow>
                                <AssaRow>
                                    <AssaSelect name="market" error={errors.market && touched.market}>
                                        <option value="" disabled selected>Solutions by market *</option>
                                        {solutionsByMarket.map((s) => <option key={s.id}>{s.name}</option>)}
                                    </AssaSelect>
                                </AssaRow>
                                <AssaRow>
                                    <AssaSelect name="challenge">
                                        <option value="" disabled selected>Solutions by challenge</option>
                                        {solutionsByChallenge.map((s) => <option key={s.id}>{s.name}</option>)}
                                    </AssaSelect>
                                </AssaRow>
                                <div className="w-full flex items-start">
                                    <Field type="checkbox" name="receiveMedia" id="receiveMedia" className="mx-2 w-[30px] mt-[5px]" />
                                    <label htmlFor="receiveMedia">Check here to receive product information from ASSA ABLOY Door Security Solutions.</label>
                                </div>
                                <div className="flex justify-center pt-10">
                                    <BlueButton type="submit">Next</BlueButton>
                                </div>
                            </div>
                            {/* <Link to="/scanner" >skip</Link> */}
                        </Form>
                        
                    )}
                </Formik>
            }
        </div>
    )
}

export default IntakeForm;