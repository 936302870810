

function EventSelect(props) {
    const { events, onEventChange } = props;

    return (
        <select placeholder="Select an Event" className="w-full p-4 mt-4" onChange={(e)=> onEventChange(e.target.value)}>
            <option disabled selected>Select an Event</option>
            {events && events.map((event)=>{
                return <option key={event.id} value={event.id}>{event.attributes.Name}</option>
            })}
        </select>
    )
}

export default EventSelect