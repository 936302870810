import Button from "../components/Button";

function Confirm() {
    return (
        <div className="bg-white grow px-5">
            <div className="max-w-sm text-white mx-auto flex justify-center flex flex-col ">
                <h1 className="text-assa-blue pt-10">Thank you for visiting our ASSA ABLOY Mobile Innovation Showroom.</h1>
                <p className="pb-10 text-black">
                    We are collecting the information you requested and will send you an email within the next 24 hours.
                    <br /><br />
                    We look forward to continuing the conversation with you.
                    <br /><br />
                    - the ASSA ABLOY Door Security Solutions Team
                </p>
                
                <a href={window.matchMedia('(display-mode: standalone)').matches ? '/' : 'https://www.assaabloy.com'}>
                    <Button className="py-2 bg-white rounded text-assa-blue hover:border-white hover:bg-assa-blue hover:text-white border">Quit</Button>
                </a>
            </div>
        </div>

    );
}

export default Confirm;