
function BackButton(props) {
    return (<button {...props} className="arrow mx-auto py-2 px-4 bg-white rounded text-assa-blue hover:border-white hover:bg-assa-blue hover:text-white border flex">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180">
            <path d="M6.22998 20.23L7.99998 22L18 12L7.99998 2L6.22998 3.77L14.46 12L6.22998 20.23Z" fill="currentColor"/>
        </svg>
        <div className="pr-2">
            {props.children}
        </div>
    </button>);
}

export default BackButton;