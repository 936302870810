import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/BlueButton";

function ScannerInfo() {
    const selectedEvent = useSelector((state) => state.app.selectedEvent);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedEvent === null) {
            navigate('/event')
        }
    }, []); // eslint-disable-line

    return (
        <div className="bg-white grow px-5">
            {selectedEvent &&
                <div className="max-w-sm mx-auto">
                    <div className="py-6 flex">
                        <span className="text-assa-blue font-bold">Event Name:</span>
                        &nbsp;{selectedEvent.attributes.Name}
                    </div>
                    <img src="/images/scanNFC.png" alt="NFC Instructions" />
                    <img src="/images/scanQR.png" alt="QR Code Instructions" />
                    <div className="flex justify-center pt-10">
                        <Link to="/scanner"><Button>Get Started</Button></Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default ScannerInfo;